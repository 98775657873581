<template>
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <p class="v-toolbar__title">Property Details</p>

          <v-card class="pa-2" outlined tile>
          <v-textarea name="propertyDetails" label="" v-model="propertyDetailsContent" auto-grow></v-textarea>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click="savePropertyDetails">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <p class="v-toolbar__title primary--text">Preview</p>

          <v-card class="pa-2 pt-7" outlined tile>
            <div v-html="propertyDetailsContent"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PropertyDetailsContent",

  data: () => ({
    propertyDetailsContent: ""
  }),

  computed: {
    ...mapGetters(["propertyDetails"])
  },

  watch: {
    propertyDetails(val) {
      this.propertyDetailsContent = val;
    }
  },

  mounted() {
    this.propertyDetailsContent = this.propertyDetails;
  },

  methods: {
    ...mapActions(["getPropertyDetails"]),
    savePropertyDetails() {
      this.$store
        .dispatch("updatePropertyDetailsContent", this.propertyDetailsContent)
        .then(
          () => {},
          error => {
            // eslint-disable-next-line no-console
            console.error("response: ", error);
          }
        );
    }
  }
};
</script>
