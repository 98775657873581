<template>
  <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
      <template v-slot:top>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <div class="flex-grow-1"></div>

            <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" class="mt-5" v-on="on">New Item</v-btn>
            </template>
            
            <v-card>
                <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                <v-container>
                    <v-row>
                    <v-col cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.title" label="Title"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.order" label="Order"></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="save" :disabled="!formIsValid">Save</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
  </v-data-table>
</template>

<script>
export default {
  name: "AppList",

  props: {
    title: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {
      title: "",
      order: 0
    },
    defaultItem: {
      title: "",
      order: 0
    },
    originalItem: {
      title: "",
      order: 0
    },
    formFieldsChanged: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      let list = [
        { text: "Title", value: "title" },
        { text: "Order", value: "order", width: 100 },
        { text: "", value: "action", sortable: false, width: 100 }
      ];

      return list;
    },
    formIsValid() {
      this.updateFormFieldsChanged();
      return this.formFieldsChanged;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.originalItem = Object.assign({}, item);

      this.dialog = true;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("deleteList", {
            collection: this.collection,
            item: item
          })
          .then(
            () => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);
              this.resetItem();
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
    },
    close() {
      this.dialog = false;
      this.resetItem();
    },
    resetItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    save() {
      if (!this.editedItem.title) {
        return;
      }

      if (this.editedIndex > -1) {
        const index = this.editedIndex;

        this.$store
          .dispatch("updateList", {
            collection: this.collection,
            header: this.header,
            item: this.editedItem
          })
          .then(
            response => {
              Object.assign(this.items[index], response.item);
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      } else {
        this.$store
          .dispatch("createList", {
            collection: this.collection,
            header: this.header,
            item: this.editedItem
          })
          .then(
            response => {
              this.items.push(response.item);
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
      this.close();
    },
    updateFormFieldsChanged() {
      this.formFieldsChanged =
        this.editedItem.title !== this.originalItem.title ||
        this.editedItem.order !== this.originalItem.order;
    }
  }
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}
</style>