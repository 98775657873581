<template>
  <div>
    <AppImageWithText title="Carousel Images" collection="carousel-images" :items= carouselImages :lines="3" :singleRecord="false"  />

    <PropertyDetailsContent />

    <AppImageWithText title="Lightbox Images" collection="lightbox-images" :items= lightboxImages :lines="3" :singleRecord="false"  />

    <AppImageWithWidth title="Floor Plan Images" collection="floor-plan-images" :items= floorPlanImages :lines="1" :singleRecord="false" />

    <AppImage title="Amenities Image" collection="amenities-images" :items= amenitiesImages :lines="0" :singleRecord="true" />
    <AppList title="Amenities - Property" collection="amenities-list" header="Property" :items= amenitiesPropertyList />
    <AppList title="Amenities - Unit" collection="amenities-list" header="Unit" :items= amenitiesUnitList />


    <AppImage title="Utilities Image" collection="utilities-images" :items= utilitiesImages :lines="0" :singleRecord="true" />
    <AppList title="Utilities - Paid By Landlord" collection="utilities-list" header="Paid By Landlord" :items= utilitiesPaidByLandlordList />
    <AppList title="Utilities - Paid By Tenant" collection="utilities-list" header="Paid By Tenant" :items= utilitiesPaidByTenantList />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppImage from "@/components/admin/AppImage";
import AppImageWithText from "@/components/admin/AppImageWithText";
import AppImageWithWidth from "@/components/admin/AppImageWithWidth";
import AppList from "@/components/admin/AppList";
import PropertyDetailsContent from "@/components/admin/PropertyDetailsContent";

export default {
  name: "PropertyDetails",

  components: {
    AppImage,
    AppImageWithText,
    AppImageWithWidth,
    AppList,
    PropertyDetailsContent
  },

  computed: {
    ...mapGetters([
      "carouselImages",
      "lightboxImages",
      "floorPlanImages",
      "amenitiesImages",
      "amenitiesPropertyList",
      "amenitiesUnitList",
      "utilitiesImages",
      "utilitiesPaidByLandlordList",
      "utilitiesPaidByTenantList"
    ])
  }
};
</script>
